.sing_up_main {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sing_up_main_c {
    width: 70%;
    height: 600px;
    background-color: #fff;
    display: flex;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12);
}

.main_heading {
    font-size: 32px;
    color: #124A99;
    font-weight: 600;
}

.sub_heading {
    font-size: 12px;
    color: rgba(189, 189, 189, 1);
}

.sing_left {
    width: 400px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sing_left_logo {
    width: 20rem;
}

.l_side {
    width: 400px;
    height: 600px;
    border: solid #124A99 1px;
    background: #124A99;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: 25px;
}

.l_side .cen {
    height: fit-content;
    min-width: 370px;
    padding: 0 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.l_side_D {
    width: 75%;
}

.f_L_Btn_L {
    width: -webkit-fill-available;
    display: flex;
    justify-content: flex-end;
    padding: 30px 0;
}

.r_side {
    width: 100%;
    height: auto;
    background-color: #fff;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
    /* padding: 50px 0; */
}


.sing_up_submain .r_side .feild .flex .input {
    width: 100%;
    min-height: 75px;
}

.sing_up_submain .r_side .feild {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0 40px;
}

.form-control {
    padding: 0 40px !important;
}

.flag-dropdown .active {
    justify-content: flex-start;
    align-items: flex-end;
}

.flag-dropdown .country-list {
    width: 260px !important;
}

.send_OTP {
    font-size: 12px;
    font-weight: bold;
    display: flex;
}

.send_OTP input {
    border: none;
}

.send_OTP div {
    min-width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgb(211, 215, 225);
    border-left: none;
    border-radius: 0px 3px 3px 0px;
    gap: 10px;
}

.iconC {
    font-size: 1rem;
    height: 15px;
    color: green;
}

.red {
    color: red;
}

.see_Pass {
    display: flex;
    align-items: center;
    justify-content: center;
}

.see_Pass i {
    border: 1px solid rgb(211, 215, 225) !important;
    height: 39px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px 3px 3px 0px !important;
}

.footer_Sub_Btn {
    cursor: 'pointer'
}

.flex_footer_btn .footer_Sub_Btn,
.flex_footer_btn .footer_Sub_Btn:hover {
    background-color: #124A99;
    border: 1px solid #124A99;
}


.flex_footer_btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* margin: 15px auto; */
    /* width: 95%; */
}

.terms-condition {
    font-size: 12px;
    width: 115px;
    display: flex;
    align-items: center;
    color: rgba(168, 168, 168, 1);
    gap: 5px;
}

.f_L_Btn,
.f_L_L {
    display: flex;
    align-items: center;
    gap: 10px;
}

.f_L_L {
    font-size: 12px;
    color: rgba(168, 168, 168, 1);
}

.f_L_L span {
    cursor: pointer;
    color: var(--blue-700);
    font-weight: bold;
}

.flex_footer_btn_sub {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
}

.p-component-overlay {
    background: #0000004f !important;
}


.otp-input-field {
    border: none;
    padding: unset;
    outline: none;
    width: 40px !important;
    border: 1px solid #cccccc;
    height: 40px;
    font-size: 25px;
}

.user-mail-otp {
    color: rgb(0, 114, 180);
}

.sing_up_main_Dialog {
    width: 30rem !important;
}

.sing_up_main_Dialog .otp-container {
    justify-content: center !important;
    gap: 20px;
}

.back {
    flex-direction: column !important;
}

.r_side .autoH {
    top: 0 !important;
}

.aling_DD {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: auto;
    gap: 10px;
}

.sing_up_main_Dialog .otp-input {
    text-align: center;
}

.sing_up_main_Dialog .otp_footer {
    width: -webkit-fill-available;
    display: flex;
    /* justify-content: end; */
    gap: 25px;
    justify-content: flex-end;
}

/* .r_side .feild .flex .input {
    min-height: 75px;
} */

.spin-wrapper {
    background: rgb(255 255 255 / 23%);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;
}

.web_hide {
    display: none;
}

.feild .input input {
    margin: unset !important;
    padding: 0 10px;
    height: 40px;
    display: flex;
    align-items: center;
    box-shadow: unset !important;
}

.r_side .feild .flex .input {
    height: auto;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.react-tel-input .flag-dropdown,
.sign .form-control {
    border: unset;
}

.ph_aling {
    border: 1px solid #CACACA;
    border-radius: 6px;
}

.ph_aling,
.ph_aling .cursor-pointer {
    display: flex;
    align-items: center;
}

.p_OTP {
    height: 40px;
    min-width: 100px;
    justify-content: center;
}

.unique-email-button {
    border-radius: 0px 3px 3px 0px !important;
}

.feild .input textarea {
    margin: unset !important;
    padding: 2px 10px;
    height: 60px;
    display: flex;
    align-items: center;
}

.form-control {
    padding: 0px 0px 0px 60px !important;
}

.react-tel-input .selected-flag {
    width: 50px;
    padding: 0 0 0 13px;
}

.feild .flex {
    display: flex;
    gap: 20px;
    margin: initial;
    width: 100%;
}

.feild .flex .input {
    width: 100%;
}

.footer_Sing {
    width: -webkit-fill-available;
    justify-content: space-between;
    flex-wrap: wrap;
}


.box_Grid {
    border: 1px solid #e8e9eb;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

@media (min-width:1000px) and (max-width:1100px) {
    .l_side .cen {
        height: fit-content;
        min-width: 335px;
        padding: 0 15px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .l_side {
        width: 360px;
    }

    .sing_left_logo {
        width: 18rem;
    }
}

@media (max-width:1000px) {
    .l_side {
        display: none;
    }

    .r_side {
        border: 2px solid #124A99;
        border-radius: 8px;
        gap: 10px;
        padding: 50px 30px;
        flex-direction: column;
    }

    .main_heading {
        border-radius: 8px;
        color: #124A99;
        /* background: #124A994a; */
        min-height: 50px;
        padding: 0 20px;
        display: flex;
        align-items: center;
    }

    .mob_Footer {
        gap: 10px;
        justify-content: center;
        flex-direction: column;
        height: 11vh;
        align-items: normal;
    }

    .mob_Footer button {
        height: 15vh;
        width: fit-content;
        margin: auto;
    }

    .web_hide {
        display: flex;
        justify-content: center;
        width: -webkit-fill-available;
    }

    .main_heading {
        font-size: 25px;
        padding: 0 10px;
    }

}

@media (max-width:575px) {

    .r_side {
        padding: 25px 15px;
    }

    .sing_up_main_Dialog .otp_footer {
        flex-direction: row;
    }

    .sing_up_main_c {
        width: 90%;
    }

    .autoH {
        height: auto !important;
    }

    .r_side .feild .flex .input {
        width: 100%;
    }

    .main_heading {
        padding: 0 10px;
        font-size: 20px;
    }

    .r_side .feild br {
        display: none;
    }

    .flex_footer_btn {
        /* flex-direction: column-reverse; */
        align-items: end;
    }

    /* .flex_footer_btn .flex_footer_btn_sub {
        width: -webkit-fill-available;
    } */

    .p_OTP,
    .phoneFeild {
        height: 35px;
    }

    .otp-input-field {
        width: 30px !important;
        height: 35px;
    }

    .flex_footer_btn_sub {
        width: -webkit-fill-available;
        align-items: center;
    }

    .footer_Sing {
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }


}