.header-maintain {
  background-color: #f9f8ff;
  width: 100%;
}

button {
  cursor: pointer;
}

.scrollToTopButton {
  position: fixed;
  bottom: 50px;
  right: 20px;
}

.al_N {
  position: relative;
  top: 80px;
}
