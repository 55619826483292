body {
  margin: 0;
  font-family: 'Inter', 'Helvetica', 'Arial', 'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary-bg-color: #124A99;
  --primary-hover-color: #1563c1;
  --primary-active-color: #0f3d73;
  --primary-text-color: #fff;
}

/* Tost */

.p-toast-message-content {
  gap: 15px;
  flex-direction: row !important;
}

.p-toast-message-text {
  width: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  border-right: 2px solid #ffff;
  gap: 5px;
}

.p-toast-message-content {
  align-items: center;
}

.p-toast-detail {
  font-size: 14px;
}

.p-toast-top-right {
  top: 80px;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-text,
.p-toast .p-toast-message .p-toast-message-content .p-toast-detail,
.p-toast .p-toast-message {
  margin: unset;
}

.p-toast-icon-close,
.p-toast .p-toast-message.p-toast-message-success,
.p-toast .p-toast-message.p-toast-message-error,
.p-toast .p-toast-message.p-toast-message-warning,
.p-toast .p-toast-message.p-toast-message-info,
.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close,
.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close,
.p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
  color: #fff3f9;
}

.p-toast .p-toast-message.p-toast-message-error {
  background: #d81a1a;
  border: solid #0000005e;
  border-width: 0 0 0 6px;
}

.p-toast .p-toast-message.p-toast-message-success {
  background: #11805d;
  border: solid #0000005e;
  border-width: 0 0 0 6px;
}

.p-toast .p-toast-message.p-toast-message-warning {
  background: #eb8600;
  border: solid #0000005e;
  border-width: 0 0 0 6px;
}

.p-toast .p-toast-message.p-toast-message-info {
  background: #0e65d0;
  border: solid #0000005e;
  border-width: 0 0 0 6px;
}

.p-toast div {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

/* OTP Box */

.p-dialog-mask.p-component-overlay {
  padding: 0 25px;
}

.p-dialog .p-dialog-header .p-dialog-title {
  font-size: 18px;
}

.sing_up_main_Dialog .p-dialog-header {
  align-items: center;
  background-color: #124a99;
  padding: 0 20px;
}

.sing_up_main_Dialog .p-dialog .p-dialog-header .p-dialog-title {
  font-size: 16px;
}

.sing_up_main_Dialog .p-dialog-header,
.sing_up_main_Dialog .p-dialog-header-icons {
  height: 50px;
}

.sing_up_main_Dialog .p-dialog-header,
.sing_up_main_Dialog .p-dialog-header-close {
  color: #fff !important;
}

.sing_up_main_Dialog .p-dialog-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding: 25px;
}

.sing_up_main_Dialog .verificationOTP {
  color: #124A99;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  border-color: transparent;
  background: #fff3f93b;
}

.p-dialog .p-dialog-footer button {
  margin: unset;
}

/* .p-inputtext */

.p-inputtext:enabled:focus {
  border-color: #ced4da !important;
}

.p-inputtext:enabled:hover {
  border-color: #ced4da !important;
}

.p-multiselect-panel {
  width: 209px !important;
}

.contact-heading {
  color: #093EA4;
  font-size: 35px;
  font-weight: 600;
  text-align: center;
  margin: 20px 0;
}

.landing_Sub_C {
  font-size: 22px;
  color: #858585;
  line-height: 45px;
  font-weight: 600;
}

.p-menuitem-separator svg {
  color: #3f51b5;
}

































.max_W {
  max-width: 1400px;
  margin: auto;
}

.fLE_X {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tex_A_Left {
  text-align: left;
}

.no_select {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.h_Full {
  height: -webkit-fill-available;
}

.fleX {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fleX_C {
  flex-direction: column;
}

.flex_W {
  flex-wrap: wrap;
}

.g_5 {
  gap: 5px;
}

.g_10 {
  gap: 10px;
}

.g_15 {
  gap: 15px;
}

.g_25 {
  gap: 25px;
}

.p_D25 {
  padding: 20px;
}

.pa_25 {
  padding: 25px;
}

.pa_25_0 {
  padding: 25px 0;
}

.p-breadcrumb {
  border: none;
}

/* Btn? */



.bTN {
  width: fit-content;
  height: 35px;
  padding: 0 15px;
  color: var(--primary-text-color);
  background-color: var(--primary-bg-color);
  border: 1px solid #124a99;
  font-size: 14px;
  cursor: pointer;
  border-radius: 3px;
  transition: background-color 0.3s ease;
}

.bTN:hover {
  background-color: var(--primary-hover-color) !important;
}

.bTN:focus {
  outline: none;
}

.bTN:active {
  background-color: var(--primary-active-color);
}

.t_bTN {
  background: transparent;
  color: #124a99;
}

.t_bTN:hover {
  color: #124a99;
}

/* Input */

.p-inputtext {
  font-size: 14px;
}

.in_PUT,
.input {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.label {
  color: #525761;
  display: flex;
  font-family: Inter, Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: 600;
  gap: 5px;
}

.error-message {
  font-size: 12px;
  color: #d81a1a;
}

/* CheckBox */

.auto_Fit {
  overflow: auto;
  max-height: max-content;
}


.tab-head-btn {
  gap: 15px;
}

.Administration_Btn {
  border: 1px solid #124A99;
}

.p-checkbox .p-checkbox-box.p-highlight,
.p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover,
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  background: var(--primary-bg-color);
}

.p-checkbox .p-checkbox-box.p-highlight,
.p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover,
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover,
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: var(--primary-bg-color);
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  border-color: #ced4da;
}


.font-orange-500 {
  background-color: #fef2c2;
}

.feild .input .label span {
  color: red;
}

.errors-messages {
  width: -webkit-fill-available;
  font-size: 12px;
}


.tab-head-btn .p-button .pi {
  color: #124a99 !important;
  font-weight: bolder !important;
}

.tbBle_R_Btn {
  width: 35px !important;
  height: 34px !important;
  padding: unset !important;
  border-radius: 50%;
}

.p-datatable-table {
  border: 1px solid rgb(209, 209, 209);
}

.p-datatable.p-datatable-selectable .p-datatable-tbody>tr.p-selectable-row:focus {
  outline: unset;
}

/* Heading  */

.heading {
  font-weight: bold;
  color: rgb(18, 74, 153);
}

.p-paginator .p-dropdown {
  width: unset;
}

.footer_sec_btn {
  align-items: center;
  display: flex;
  gap: 20px;
  height: 100px;
  justify-content: flex-end;
  padding: 0 20px;
  width: 100%;
}

.react-tel-input .selected-flag {
  background: white;
  border: 1px solid #ced4da;
}

.bTN:focus .p-inputtext:enabled:focus .p-button:focus,
.p-button:focus,
.p-checkbox .p-checkbox-box,
.p-checkbox :focus,
.p-inputtext:enabled:focus,
.p-breadcrumb .p-breadcrumb-list li .p-menuitem-link:focus,
.p-dialog .p-dialog-header .p-dialog-header-icon:focus,
.p-radiobutton .p-radiobutton-box.p-highlight,
.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover,
.p-multiselect:not(.p-disabled).p-focus {
  box-shadow: unset !important;
}

/* Radio Button  */

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: var(--primary-bg-color);
}

.p-radiobutton .p-radiobutton-box.p-highlight,
.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  background: var(--primary-bg-color);
  border-color: var(--primary-bg-color);
}

/* Data Table */

.p-datatable .p-datatable-tbody>tr>td {
  height: 60px;
}

.avaTar_Up {
  width: 170px !important;
  height: 170px !important;
}

.avaTar_Up_ {
  font-size: 25px;
  font-weight: 600;
  color: #124A99;
}

.p-paginator .p-paginator-pages .p-paginator-page,
.p-paginator .p-paginator-last,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev {
  min-width: 25px;
  height: 25px;
}

/* Other_Css */

.head_T {
  background: var(--gray-50);
  border: 1px solid #d1d1d1;
  height: 50px;
  padding: 0 20px;
}

.head {
  font-weight: 600;
  gap: 5px;
  height: 50px;
  width: 100%;
}

.head,
.pend-count-styl {
  align-items: center;
  display: flex;
}

.none {
  display: none;
}

.ali_Fs {
  align-items: flex-start;
}

.g_10 {
  gap: 10px;
}


.curSor {
  cursor: pointer;
}

.bLOG_H_Su {
  color: #858585;
  display: flex;
  font-size: 20px;
  justify-content: center;
  margin: auto;
  max-width: 1400px;
  text-align: center;
  width: 100%;
}




.pend-count-styl {
  background-color: #fff;
  border: 2px solid #124a99;
  border-radius: 8px;
  color: red;
  font-size: 20px;
  justify-content: center;
  width: 40px;
}

.info_D {
  display: flex;
  flex-direction: column;
  height: auto !important;
  justify-content: space-evenly;
  min-height: 50px !important;
  padding: 0 20px;
  width: auto;
}

.info_D .leable {
  color: #5d6170;
  font-weight: 600;
}

.info_D .value {
  color: #8f8f8f;
  font-weight: 500;
}

.div {
  border-bottom: 1px solid #8f8f8f;
}

.big_name {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  height: 30px;
  justify-content: flex-start;
  overflow: auto;
  padding: 0 20px;
}

.bLOG {
  margin: auto;
  width: 67px;
}

.bLOG,
.bLOG div {
  background: #093ea4;
  height: 6px;
}

.bLOG div {
  border-radius: 50%;
  left: 74px;
  position: relative;
  width: 9px;
}

/* Coman */

.over_Flow {
  overflow: auto;
  /* height: 70vh; */
}


.landing_Container {
  padding: 50px 25px;
  position: relative;
  top: 80px;
}

/* .menubar_slider {
  padding: unset;
}

.menubar_slider .p-sidebar-left .p-sidebar {
  width: auto !important;
}

._Slider .p-sidebar .p-sidebar-content {
  padding: unset !important;
} */






















@media (max-width: 1200px) {
  .contact-heading {
    font-size: 30px;
  }

  .landing_Sub_C {
    font-size: 18px;
    line-height: 50px;
  }

  /* .over_Flow {
    height: 65vh;
  } */
}


@media (max-width: 1000px) {}


@media (max-width: 800px) {
  .contact-heading {
    font-size: 25px;
  }

  /* .over_Flow {
    height: 60vh;
  } */

  .web_View {
    display: none;
  }
}

@media (max-width: 575px) {

  .p-toast {
    width: 280px;
  }

  .landing_Container {
    padding: 30px 15px;
    top: 80px;
  }

  .p-dialog .p-dialog-header .p-dialog-title {
    font-size: 16px;
  }

  .contact-heading {
    font-size: 22px;
  }

  .landing_Sub_C {
    font-size: 14px;
    line-height: 28px;
  }

  .feild .flex .input {
    width: 100%;
  }

  .c .flex {
    flex-direction: column;
  }

  .p-inputtext,
  .see_Pass i,
  .p-multiselect-label {
    height: 35px !important;
  }

  .feild .flex {
    flex-direction: column;
  }

  .g_25 {
    gap: 15px;
  }

  /* .over_Flow {
    height: 55vh;
  } */

  .p_D25,
  .pa_25 {
    padding: 10px;
  }
}