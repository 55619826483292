.fall_Back {
    /* background: #00000029; */
    background: #0000004f;
    font-size: 1.5rem;
    position: fixed;
    z-index: 9999;
}

.spin_L {
    width: 80px;
    height: 80px;
    border: 8px solid #f3f3f3;
    border-top: 8px solid var(--primary-bg-color);
    border-radius: 50%;
    animation: spin 2s linear infinite;
    background: var(--primary-active-color);
}

.spin_C {
    font-size: 10px;
    font-weight: 800;
    color: #ffff;
    position: fixed;
    cursor: default;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}